<template>
    <div style="display: flex;">
        <div class="upload_video">
            <div class="upload_progress" v-if="showProgress">
                <div class="upload_img" v-if="showProgress">
                    {{ progressNum }}%
                </div>
                <div class="upload_text">
                    上传中
                </div>
            </div>
            <div v-else>
                <video v-if="trainVideo" controls="controls" class="video_cover mr20" :poster="coverUrl"
                    :src="trainVideo"></video>
                <el-upload v-else class="upload_video" action="#" accept="video/mp4,video/mov" :show-file-list="false"
                    :before-upload="beforeUpload" :http-request="uploadBtn">
                    <div class="upload_img">
                        <i class="el-icon-plus"></i>
                    </div>
                    <div class="upload_text">
                        上传视频
                    </div>
                </el-upload>
            </div>
        </div>
        <div class="absolute">
            <el-upload action="#" accept="video/mp4,video/mov" v-if="trainVideo" :show-file-list="false"
                :before-upload="beforeUpload" :http-request="uploadBtn">
                <div class="re_upload">重新上传</div>
            </el-upload>
        </div>
    </div>
</template>

<script>
import { client, getFileNameUUID } from '@/utils/alioss.js'
export default {
    data() {
        return {
            trainVideo: '',
            coverUrl: '',
            duration: '',
            // 上传进度
            showProgress: false,
            progressNum: 0,
        };
    },
    created() {

    },
    methods: {
        uploadBtn(file) {
            var that = this
            let temporary = file.file.name.lastIndexOf(".");
            let fileNameLength = file.file.name.length;
            let fileFormat = file.file.name.substring(
                temporary + 1,
                fileNameLength
            )
            let fileName = getFileNameUUID() + "." + fileFormat;
            let f_name = 'yc-mini/' + that.dateFormat()
            client().multipartUpload(`${f_name}/${fileName}`, file.file, {
                progress: function (p) {
                    that.showProgress = true
                    that.progressNum = Math.floor(p * 100)
                    if(p==1){
                        that.showProgress = false
                    }
                }
            }).then(res => {
                    that.trainVideo = 'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/'+res.name
                    that.$emit('successVideo', that.trainVideo, that.duration)
                })
                .catch(err => {
                    console.log("err:", err);
                });
        },
        // 获取当前日期
        dateFormat() {
            var date = new Date()
            var year = date.getFullYear()
            var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
            var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            return year + '-' + month + '-' + day
        },
        //
        videoUrl(val) {
            if (val.indexOf("mp4") != -1 || val.indexOf("mov") != -1) {
                this.trainVideo = val
            } else {
                this.trainVideo = ''
            }
        },
        videoSize(file) {
            var that = this
            return new Promise((resolve, reject) => {
                const _URL = window.URL || window.webkitURL
                const videoElement = document.createElement('video')
                videoElement.addEventListener('loadedmetadata', function (_event) {
                    const duration = Math.round(videoElement.duration) //时长
                    file.duration = duration
                    that.duration = duration
                    resolve()
                })
                videoElement.src = _URL.createObjectURL(file)
            }).then(
                () => {
                    return file
                },
                () => {
                    this.$message.error(file.name + '上传失败')
                    return Promise.reject()
                }
            )
        },
        beforeUpload(file) {
            const imgType = file.type === 'video/mp4' || file.type === 'video/mov'
            const isLt500M = file.size / 1024 / 1024 < 500;
            if (!imgType) {
                this.$message.error("上传视频只能是 mp4和mov 格式!");
                return false;
            }
            if (!isLt500M) {
                this.$message.error("上传视频大小不能超过 500M!");
                return false;
            }
            return this.videoSize(file)
        },

    },
};
</script>

<style scoped lang="scss">
.upload_video {
    display: flex;
    position: relative;

    .title_text {
        width: 78px;
        font-size: 14px;
        text-align: right;
        color: #333333;
        margin-right: 10px;
        line-height: 40px;

        span {
            color: #e00c25;
        }
    }

    ::v-deep .el-upload {
        width: 100%;
        height: 100%;
    }
    .upload_progress{
        width: 354px;
        height: 205px;
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px dashed #dddfe6;
        .upload_img {
            width: 24px;
            height: 24px;
            margin: 70px auto 0;

            img {
                width: 100%;
                height: 100%;
            }
            .el-icon-plus{
                font-size: 16px;
                color: #2e4bf2;
            }
        }

        .upload_text {
            font-size: 14px;
            color: #2e4bf2;
            line-height: 20px;
            text-align: center;
            padding-top: 6px;
        }
    }

    .upload_video {
        width: 354px;
        height: 205px;
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px dashed #dddfe6;
        cursor: pointer;
        position: relative;

        .upload_img {
            width: 24px;
            height: 24px;
            margin: 70px auto 0;

            img {
                width: 100%;
                height: 100%;
            }
            .el-icon-plus{
                font-size: 16px;
                color: #2e4bf2;
            }
        }

        .upload_text {
            font-size: 14px;
            color: #2e4bf2;
            line-height: 20px;
            text-align: center;
            padding-top: 6px;
        }
    }

    .video_cover {
        width: 354px;
        height: 205px;
        border-radius: 4px;
        -o-object-fit: contain;
        object-fit: contain;
        background: #1a1a1a;
    }

}

.absolute {
    margin-top: 160px;
    font-size: 14px;
    color: #2e4bf2;
    line-height: 20px;

    .empower {
        width: 90px;
        text-decoration: underline;
        cursor: pointer;
    }

    .re_upload {
        width: 90px;
        text-decoration: underline;
        display: flex;
        margin-top: 18px;

        img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 4px;
        }
    }
}

.mr20 {
    margin-right: 20px;
}
</style>
