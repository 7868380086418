// 引入ali-oss
let OSS = require('ali-oss')
export function client() {
  return new OSS({
    region: 'oss-cn-shenzhen',
    accessKeyId: 'LTAI5tGG2JFUBdR1aqnfm61U',
    accessKeySecret: 'US9qFrOnJc1JUF8vjG3UdbNVIzIGLL',
    bucket: 'yc-digital'
  })
}
/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}