<template>
    <div class="add box_b">
        <!--  -->
        <div class="add_main box_b">
            <div class="vue_title">
                <span @click="back"><i class="el-icon-arrow-left"></i>返回</span>
                <p> {{id?'修改':'添加'}}</p>
            </div>
            <!--  -->
            <div class="add_info box_b" v-loading="loading">
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>标题</div>
                    <div class="t_right">
                        <el-input v-model="info.title" size='small' clearable placeholder="请输入标题"></el-input>
                    </div>
                </div>

                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>学习人数</div>
                    <div class="t_right">
                        <el-input v-model="info.number" size='small' clearable maxlength="10" @input="limitNum" placeholder="请输入学习人数"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>课程视频</div>
                    <div class="t_right">
                        <upload-video ref="uploadVideo" @successVideo="successVideo"></upload-video>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6">排序</div>
                    <div class="t_right">
                        <el-input v-model="info.sort" size='small' clearable @input="limitSort" placeholder="请输入排序(排序从大到小)"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>学习重点</div>
                    <div class="edit">
                        <div style="border: 1px solid #DCDFE6;">
                            <Toolbar style="width: 100%;border-bottom: 1px solid #DCDFE6" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
                            <Editor style="height: 350px; width: 100%; overflow-y: hidden;" v-model="info.content" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
                        </div>
                    </div>
                </div>

            </div>
            <!--  -->
            <div class="dialog-footer">
                <el-button type="primary" size='small' @click="confirm" :loading="btnLoading">确 定</el-button>
                <el-button size='small' @click="chongzhi">重 置</el-button>
            </div>

        </div>
    </div>
</template>
  
  <script>
import '@wangeditor/editor/dist/css/style.css'
import UploadVideo from '../upload_video/index.vue' //上传视频
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    name: "notice_add",
    components: {
        Editor,
        Toolbar,
        UploadVideo,
    },
    props: {

    },
    data() {
        return {
            headers: {
                'Authorization': this.$store.state.token
            },
            loading: false,
            btnLoading: false,
            info: {
                title: "",
                number: "",
                sort:0,
                duration: "",
                video_link: "",
                content: "",
            },
            catalogue_id: "",
            id: "",
            editor: null,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 配置上传视频
                    uploadVideo: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 继续其他菜单配置...
                }
            },
            mode: 'default', // or 'simple'
        };
    },

    created() {
        this.catalogue_id = this.$route.query.catalogue_id
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getInfo()
        }
    },

    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        // 富文本上传图片
        update(file, insertFn) {
            var that = this;
            let files = event.target.files[0];
            let form = new FormData();
            form.append("file", files);
            that.$publicApi.uploadFile(form).then(res => {
                if (res.code == 1000) {
                    insertFn(res.result.url, file.name, res.result.url)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        confirm() {
            if (this.info.title == '') {
                this.$errMsg('请输入标题')
                return
            }
            if (this.info.number == '') {
                this.$errMsg('请输入学习人数')
                return
            }
            if (this.info.video_link == '') {
                this.$errMsg('请上传课程视频')
                return
            }
            if (this.$testVerify.isContent(this.info.content)) {
                this.$errMsg('请输入学习重点')
                return
            }
            // 修改
            if (this.id) {
                this.edit()
            } else {
                // 添加
                this.add()
            }
        },
        // 添加
        add() {
            this.btnLoading = true;
            var params = {
                catalogue_id: this.catalogue_id,
                duration: this.info.duration,
                title: this.info.title,
                number: this.info.number,
                content: this.info.content,
                video_link: this.info.video_link,
                sort: this.info.sort,
            }
            this.$activityApi.activityCatalogAdd(params).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.back()
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },
        edit() {
            this.btnLoading = true;
            var params = {
                catalogue_id: this.catalogue_id,
                duration: this.info.duration,
                title: this.info.title,
                number: this.info.number,
                content: this.info.content,
                video_link: this.info.video_link,
                sort: this.info.sort,
            }
            this.$activityApi.activityCatalogEdit(params, this.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.back()
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },

        getInfo() {
            this.loading = true;
            this.$activityApi.activityCatalogInfo(this.id).then(res => {
                if (res.code == 1000) {
                    this.info = {
                        title: res.result.title,
                        sort: res.result.sort,
                        duration: res.result.duration,
                        number: res.result.number,
                        video_link: res.result.video_link,
                        content: res.result.content,
                    }
                    this.$refs.uploadVideo.trainVideo = res.result.video_link
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.$errMsg(res.message)
                }
            })
        },
        chongzhi() {
            this.info = {
                title: "",
                number: "",
                duration: "",
                video_link: "",
                content: "",
                sort: 0,
            }
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },

        // 视频上传
        successVideo(val, duration) {
            this.info.video_link = val
            this.info.duration = duration
        },
        // 输入框限制
        limitNum(e) {
            this.info.number = e.replace(/[^0-9.]/g, '')
        },
        limitSort(e) {
            this.info.sort = e.replace(/[^0-9.]/g, '')
        },
        back() {
            this.$router.back()
        },
    },
}
  </script>
  <style lang="scss" scoped>
// @import "../../assets/style/common.less";

.add {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .add_main {
        width: 100%;
        height: 100%;
    }

    .sms_title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f5fa;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .add_info {
        width: 100%;
        height: calc(100% - 90px);
        padding: 15px 0;
        overflow-y: auto;

        .info_text {
            width: 100%;
            padding: 12px 0 0;
            overflow: hidden;
            display: flex;
            .switch {
                width: 90px;
                text-align: right;
                margin-right: 14px;
            }
            .edit {
                width: 1000px;
                margin-left: 10px;
                padding-top: 6px;
                position: relative;
                z-index: 1000;
            }
            .t_left {
                float: left;
                width: 110px;
                line-height: 32px;
                text-align: right;
            }
            .t_right {
                float: left;
                width: 500px;
                margin-left: 12px;
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                .r_img {
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    border: 1px solid #e8eaec;
                    background-color: #f5f7fa;
                    border-radius: 5px;
                    .r_img_no {
                        padding-top: 20px;
                        font-size: 14px;
                        color: #94979f;
                        i {
                            font-size: 18px;
                        }
                        div {
                            padding-top: 5px;
                        }
                    }
                }
                .r_txt {
                    padding-left: 10px;
                }
                .el-select {
                    width: 100%;
                }
            }
            .t_right_select {
                float: left;
                width: 500px;
                margin-left: 12px;
                .el-select {
                    width: 100%;
                }
            }
            .radio {
                padding-left: 10px;
                display: flex;
                align-items: center;
                height: 32px;
            }
        }
    }
}
.upload {
    width: 680px;
    display: flex;
    flex-wrap: wrap;

    .r_img {
        width: 80px;
        height: 80px;
        margin-right: 8px;
        margin-bottom: 8px;
        background-color: #f5f7fa;
        border-radius: 4px;

        .r_img_no {
            padding-top: 20px;
            i {
                font-size: 20px;
            }

            div {
                padding-top: 5px;
            }
        }
    }

    .image {
        width: 80px;
        height: 80px;
        margin-right: 8px;
        margin-bottom: 8px;
        position: relative;
        cursor: pointer;

        img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
        }

        .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.3);
            display: none;
        }

        .el-icon-close {
            position: absolute;
            right: 3px;
            top: 3px;
            display: none;
            color: #ffffff;
            font-size: 20px;
            z-index: 100;
        }
    }

    .image:hover {
        .el-icon-close {
            display: block;
        }

        .mask {
            display: block;
        }
    }

    .r_txt {
        padding-left: 10px;
    }

    .el-select {
        width: 100%;
    }
}
.dialog-footer {
    padding-left: 50px;
    padding-bottom: 20px;
}
.box_b {
    box-sizing: border-box;
}
.f_s2 {
    font-size: 14px;
    line-height: 20px;
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}
.color1 {
    color: #e51d41;
}

.color6 {
    color: #606266;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
::v-deep .el-upload {
    width: 100%;
    height: 100%;
}
</style>
  